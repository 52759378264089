import React, { useState, useEffect } from 'react';
import ProfilePost from './ProfilePost';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const ProfileContent = ({ userId }) => {
  const [userData, setUserData] = useState(null);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    if (userId) {
      axios.get(`${apiBaseUrl}/api/users/${userId}`)
        .then(response => setUserData(response.data))
        .catch(error => console.error('Error fetching user data:', error));
    }
  }, [userId, apiBaseUrl]);

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <>
    <Helmet>
        {/* Primary Meta Tags */}
        <title>{userData.displayName}'s Profile - DropTracker.io</title>
        <meta name="title" content={`${userData.displayName} - DropTracker.io`} />
        <meta name="description" content={`View ${userData.displayName}'s drops, achievements, and history on the DropTracker.`} />
        <meta name="keywords" content="droptracker, osrs, clan, tracking, drops, metrics, events" />
        <meta name="author" content="droptracker.io" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://www.droptracker.io/profile/${userId}`} />
        <meta property="og:title" content={`${userData.displayName} - DropTracker.io`} />
        <meta property="og:description" content={`View ${userData.displayName}'s drops, achievements, and history on the DropTracker.`} />
        <meta property="og:image" content="https://www.droptracker.io/img/droptracker-small.gif" />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={`https://www.droptracker.io/profile/${userId}`} />
        <meta property="twitter:title" content={`${userData.displayName} - DropTracker.io`} />
        <meta property="twitter:description" content={`View ${userData.displayName}'s drops, achievements, and history on the DropTracker.`} />
        <meta property="twitter:image" content="https://www.droptracker.io/img/droptracker-small.gif" />

        {/* Additional Meta Tags */}
        <link rel="canonical" href={`https://www.droptracker.io/profile/${userId}`} />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <div className="profile-sidebar">
        <div className="desktop-sticky-top">
          
          <h4>Profile: {userData.name}</h4>
          <div className="mb-3 text-inverse text-opacity-50 fw-bold mt-n2">{userData.displayName}</div>
          <p></p>
          <div className="mb-1">
            <i className="bi bi-people text-inverse text-opacity-50"></i> Clan: {userData.clan ? userData.clan.displayName : 'No clan'}
          </div>
          <div className="mb-3">
            <i className="fa fa-link fa-fw text-inverse text-opacity-50"></i><small>Accounts:</small> {userData.rsns}
          </div>
          <hr className="mt-4 mb-4" />
          {/*<div className="fw-bold mb-3 fs-16px">People to follow</div>
           Add people to follow */}
        </div>
      </div>

      <div className="profile-content">
        <ul className="profile-tab nav nav-tabs nav-tabs-v2">
          <li className="nav-item">
            <a href="#profile-post" className="nav-link active" data-bs-toggle="tab">
              <div className="nav-field">Submissions</div>
              <div className="nav-value">{userData.totalDropsMonth}</div>
            </a>
          </li>
          <li className="nav-item">
            <a href="#profile-followers" className="nav-link" data-bs-toggle="tab">
              <div className="nav-field">Global Rank</div>
              <div className="nav-value">{userData.globalRank}</div>
            </a>
          </li>
          <li className="nav-item">
            <a href="#profile-media" className="nav-link" data-bs-toggle="tab">
              <div className="nav-field">Total Tracked</div>
              <div className="nav-value">{userData.totalValueAllTime}</div>
            </a>
          </li>
          <li className="nav-item">
            <a href="#profile-video" className="nav-link" data-bs-toggle="tab">
              <div className="nav-field">Clan Members</div>
              <div className="nav-value">120</div>
            </a>
          </li>
          <li className="nav-item">
            <a href="#profile-followers" className="nav-link" data-bs-toggle="tab">
              <div className="nav-field">Total Drops</div>
              <div className="nav-value">{userData.totalDropsAllTime}</div>
            </a>
          </li>
        </ul>
        <div className="profile-content-container">
          <div className="row gx-4">
            <div className="col-xl-8">
              <div className="tab-content p-0">
                <ProfilePost userId={userId} />
                {/* Add other tab panes here */}
              </div>
            </div>
            <div className="col-xl-4">
              {/* Sidebar content moved here */}
              <div>
                <h4>Clan Info</h4>
                <p>{userData.clan ? userData.clan.displayName : 'No clan'}</p>
                <h4>Total Drops This Month</h4>
                <p>{userData.totalDropsMonth}</p>
                <h4>Total Drops All Time</h4>
                <p>{userData.totalDropsAllTime}</p>
                <h4>Total Value This Month</h4>
                <p>{userData.totalValueMonth}</p>
                <h4>Total Value All Time</h4>
                <p>{userData.totalValueAllTime}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileContent;
