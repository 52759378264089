import React from 'react';

// Define the RepeatButton component
function RepeatButton(props) {
    return (
        <button
            aria-label='Play again.'
            id='repeatButton'
            onClick={props.onClick}>
        </button>
    );
}

// Define the WinningSound component
function WinningSound() {
    return (
        <audio autoPlay className="player" preload="false">
            <source src="https://andyhoffman.codes/random-assets/img/slots/winning_slot.wav" />
        </audio>
    );
}

// Define the Spinner component
class Spinner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            position: this.setStartPosition(),
            timeRemaining: this.props.timer,
        };
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    }

    static iconHeight = 188;
    multiplier = Math.floor(Math.random() * (4 - 1) + 1);
    start = this.setStartPosition();
    speed = Spinner.iconHeight * this.multiplier;
    timer = null;

    forceUpdateHandler() {
        this.reset();
    }

    reset() {
        if (this.timer) {
            clearInterval(this.timer);
        }

        this.start = this.setStartPosition();

        this.setState({
            position: this.start,
            timeRemaining: this.props.timer,
        });

        this.timer = setInterval(() => {
            this.tick();
        }, 100);
    }

    setStartPosition() {
        return (Math.floor(Math.random() * 9) * Spinner.iconHeight) * -1;
    }

    moveBackground() {
        this.setState((prevState) => ({
            position: prevState.position - this.speed,
            timeRemaining: prevState.timeRemaining - 100,
        }));
    }

    getSymbolFromPosition() {
        const { position } = this.state;
        const totalSymbols = 9;
        const maxPosition = Spinner.iconHeight * (totalSymbols - 1) * -1;
        let moved = this.props.timer / 100 * this.multiplier;
        let currentPosition = this.start;

        for (let i = 0; i < moved; i++) {
            currentPosition -= Spinner.iconHeight;

            if (currentPosition < maxPosition) {
                currentPosition = 0;
            }
        }

        this.props.onFinish(currentPosition);
    }

    tick() {
        if (this.state.timeRemaining <= 0) {
            clearInterval(this.timer);
            this.getSymbolFromPosition();
        } else {
            this.moveBackground();
        }
    }

    componentDidMount() {
        clearInterval(this.timer);

        this.setState({
            position: this.start,
            timeRemaining: this.props.timer,
        });

        this.timer = setInterval(() => {
            this.tick();
        }, 100);
    }

    render() {
        const { position } = this.state;

        return (
            <div
                style={{ backgroundPosition: '0px ' + position + 'px' }}
                className="icons"
            />
        );
    }
}

// Define the main SlotPage component
class SlotPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            winner: null,
        };
        this.finishHandler = this.finishHandler.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    static loser = [
        'Not quite',
        'Stop gambling',
        'Hey, you lost!',
        'Ouch! I felt that',
        'Don\'t beat yourself up',
        'There goes the college fund',
        'I have a cat. You have a loss',
        'You\'re awesome at losing',
        'Coding is hard',
        'Don\'t hate the coder',
    ];

    static matches = [];

    handleClick() {
        this.setState({ winner: null });
        this.emptyArray();
        this._child1.forceUpdateHandler();
        this._child2.forceUpdateHandler();
        this._child3.forceUpdateHandler();
    }

    finishHandler(value) {
        SlotPage.matches.push(value);

        if (SlotPage.matches.length === 3) {
            const first = SlotPage.matches[0];
            let results = SlotPage.matches.every((match) => match === first);
            this.setState({ winner: results });
        }
    }

    emptyArray() {
        SlotPage.matches = [];
    }

    render() {
        const { winner } = this.state;
        const getLoser = () => {
            return SlotPage.loser[Math.floor(Math.random() * SlotPage.loser.length)];
        };
        let repeatButton = null;
        let winningSound = null;

        if (winner !== null) {
            repeatButton = <RepeatButton onClick={this.handleClick} />;
        }

        if (winner) {
            winningSound = <WinningSound />;
        }

        return (
            <div>
                {winningSound}
                <h1 style={{ color: 'white' }}>
                    <span>{winner === null ? 'Waiting…' : winner ? '🤑 Pure skill! 🤑' : getLoser()}</span>
                </h1>

                <div className={`spinner-container`}>
                    <Spinner onFinish={this.finishHandler} ref={(child) => { this._child1 = child; }} timer="1000" />
                    <Spinner onFinish={this.finishHandler} ref={(child) => { this._child2 = child; }} timer="1400" />
                    <Spinner onFinish={this.finishHandler} ref={(child) => { this._child3 = child; }} timer="2200" />
                    <div className="gradient-fade"></div>
                </div>
                {repeatButton}
            </div>
        );
    }
}

export default SlotPage;
