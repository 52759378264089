import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import EmbedBuilder from '../embed/EmbedBuilder';
import './ServerConfigPage.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const formatNumber = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(2) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(2) + 'K';
  }
  return num;
};

const ServerConfigPage = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const [clanDTId, setClanDTId] = useState(null);
  const [settings, setSettings] = useState(null);
  const [womId, setWomId] = useState('');
  const [embeds, setEmbeds] = useState({});
  const [clanName, setClanName] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [voiceChannels, setVoiceChannels] = useState([]);
  const [textChannels, setTextChannels] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [filterVoice, setFilterVoice] = useState('');
  const [lootBoardFilterText, setLootBoardFilterText] = useState('');
  const [dropFilterText, setDropFilterText] = useState('');
  const [filteredLootBoardChannels, setFilteredLootBoardChannels] = useState([]);
  const [filteredDropChannels, setFilteredDropChannels] = useState([]);

  const [womMemberCountFilterText, setWomMemberCountFilterText] = useState('');
  const [monthlyLootFilterText, setMonthlyLootFilterText] = useState('');
  const [filteredWomMemberCountChannels, setFilteredWomMemberCountChannels] = useState([]);
  const [filteredMonthlyLootChannels, setFilteredMonthlyLootChannels] = useState([]);
  const [lootBoardChannel, setLootBoardChannel] = useState('');
  const [dropChannel, setDropChannel] = useState('');
  const [womMemberCountVoiceChannel, setWomMemberCountVoiceChannel] = useState('');
  const [monthlyLootVoiceChannel, setMonthlyLootVoiceChannel] = useState('');
  const [openDropdown, setOpenDropdown] = useState(null);

  const [userClans, setUserClans] = useState([]);
  const { user } = useContext(AuthContext);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    if (!user) {
      navigate('/login');
    } else {
      const authToken = localStorage.getItem('token');
      if (authToken) {
        authenticateAndFetchData(authToken);
      } else {
        navigate('/login');
      }
    }
  }, [user, navigate]);

  const updateProgress = (increment) => {
    setProgress((prevProgress) => Math.min(prevProgress + increment, 100));
  };

  const handleFilterChannels = (type, event) => {
    const text = event.target.value;

    if (type === 'lootBoard') {
      setLootBoardFilterText(text);
      setFilteredLootBoardChannels(
          textChannels.filter((channel) =>
              channel.name.toLowerCase().includes(text.toLowerCase())
          )
      );
    } else if (type === 'drop') {
      setDropFilterText(text);
      setFilteredDropChannels(
          textChannels.filter((channel) =>
              channel.name.toLowerCase().includes(text.toLowerCase())
          )
      );
    } else if (type === 'womMemberCount') {
      setWomMemberCountFilterText(text);
      setFilteredWomMemberCountChannels(
          voiceChannels.filter((channel) =>
              channel.name.toLowerCase().includes(text.toLowerCase())
          )
      );
    } else if (type === 'monthlyLoot') {
      setMonthlyLootFilterText(text);
      setFilteredMonthlyLootChannels(
          voiceChannels.filter((channel) =>
              channel.name.toLowerCase().includes(text.toLowerCase())
          )
      );
    }

    if (text === '') {
      setOpenDropdown(type); // Reopen the dropdown to show all options
    }
  };



  const handleChannelSelect = (type, channel) => {
    if (type === 'lootBoard') {
      setSettings({ ...settings, loot_leaderboard_channel_id: channel.id });
      setLootBoardChannel(channel.name);
      setLootBoardFilterText(''); // Reset filter text for loot board
      setFilteredLootBoardChannels([]); // Reset filtered channels for loot board'

    } else if (type === 'drop') {
      setSettings({ ...settings, channel_id_to_post_loot: channel.id });
      setDropChannel(channel.name);
      setDropFilterText(''); // Reset filter text for drop channel
      setFilteredDropChannels([]); // Reset filtered channels for drop channel
    } else if (type === 'womMemberCount') {
      setSettings({ ...settings, voice_channel_to_display_wom_group_member_count: channel.id });
      setWomMemberCountVoiceChannel(channel.name);
      setWomMemberCountFilterText(''); // Reset filter text for WOM member count
      setFilteredWomMemberCountChannels([]); // Reset filtered channels for WOM member count
    } else if (type === 'monthlyLoot') {
      setSettings({ ...settings, voice_channel_to_display_monthly_loot: channel.id });
      setMonthlyLootVoiceChannel(channel.name);
      setMonthlyLootFilterText(''); // Reset filter text for monthly loot
      setFilteredMonthlyLootChannels([]); // Reset filtered channels for monthly loot
    }

    setOpenDropdown(null); // Close the dropdown after selection
  };

  useEffect(() => {
    if (user) {
      const authToken = localStorage.getItem('token');
      if (authToken) {
        authenticateAndFetchData(authToken);
      } else {
        navigate('/login');
      }
    } else {
      navigate('/login');
    }
  }, [user, navigate]);

// Fetch channels and other data
  const authenticateAndFetchData = async (authToken) => {
    try {
      setLoading(true);
      updateProgress(20);

      const authResponse = await axios.get(`${apiBaseUrl}/clan/check-auth`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      if (!authResponse.data.auth) {
        alert('You are not authorized to view this page.');
        navigate('/');
        return;
      }

      const userClans = authResponse.data.clans;
      setUserClans(userClans);

      if (userClans.length === 1) {
        await selectClan(userClans[0].discordServerId, authToken);
      }

      updateProgress(40);
    } catch (error) {
      setError(error.response ? error.response.data.message : error.message);
    } finally {
      setLoading(false);
      updateProgress(100);
    }
  };

// Select clan and fetch settings
  const selectClan = async (clanId, authToken) => {
    try {
      setLoading(true);
      updateProgress(20);

      const settingsResponse = await axios.get(`${apiBaseUrl}/clan/get-settings`, {
        params: { discordId: clanId, userId: String(user.discordId) },
        headers: { Authorization: `Bearer ${authToken}` },
      });

      const fetchedClanDTId = settingsResponse.data.clanId;
      const fetchedWOMId = settingsResponse.data.womId;
      const fetchedClanName = settingsResponse.data.clanName;
      const parsedSettings = JSON.parse(settingsResponse.data.clanSettings);

      setClanDTId(fetchedClanDTId);
      setSettings(parsedSettings);
      setWomId(fetchedWOMId);
      setClanName(fetchedClanName);

      await fetchEmbedsAndChannels(authToken, clanId, fetchedClanDTId);

      updateProgress(100);
    } catch (error) {
      setError(error.response ? error.response.data.message : error.message);
    } finally {
      setLoading(false);
      updateProgress(100);
    }
  };

// Use another effect to update channel names after channels are fetched
  useEffect(() => {
    if (textChannels.length > 0 && voiceChannels.length > 0 && settings) {
      if (!lootBoardChannel) {
        const lootBoardChannelName = textChannels.find(channel => channel.id === settings.loot_leaderboard_channel_id)?.name || '';
        setLootBoardChannel(lootBoardChannelName);
      }

      if (!dropChannel) {
        const dropChannelName = textChannels.find(channel => channel.id === settings.channel_id_to_post_loot)?.name || '';
        setDropChannel(dropChannelName);
      }

      if (!womMemberCountVoiceChannel) {
        const womMemberCountChannelName = voiceChannels.find(channel => channel.id === settings.voice_channel_to_display_wom_group_member_count)?.name || '';
        setWomMemberCountVoiceChannel(womMemberCountChannelName);
      }

      if (!monthlyLootVoiceChannel) {
        const monthlyLootChannelName = voiceChannels.find(channel => channel.id === settings.voice_channel_to_display_monthly_loot)?.name || '';
        setMonthlyLootVoiceChannel(monthlyLootChannelName);
      }
    }
  }, [textChannels, voiceChannels, settings]);

// Fetch embeds and channels
  const fetchEmbedsAndChannels = async (authToken, clanId, clanDTId) => {
    try {
      setLoading(true);
      updateProgress(60);

      const embedPromises = [1, 2, 3, 4].map(typeId =>
          axios.get(`${apiBaseUrl}/clan/get-embed`, {
            params: { clanDTId, typeId },
            headers: { Authorization: `Bearer ${authToken}` },
          })
      );

      const embedResponses = await Promise.all(embedPromises);
      const embedsData = embedResponses.reduce((acc, response, index) => {
        try {
          let embedData = response.data.embedData;

          if (typeof embedData === 'string') {
            try {
              embedData = JSON.parse(embedData);

              if (typeof embedData === 'string') {
                embedData = JSON.parse(embedData);
              }
            } catch (e) {
              console.error("Error parsing embed data:", e);
            }
          }

          const validatedEmbedData = {
            title: embedData.title || '',
            description: embedData.description || '',
            color: embedData.color || 0,
            fields: Array.isArray(embedData.fields) ? embedData.fields : [],
            footer: embedData.footer || { text: '', icon_url: '' },
            image: embedData.image || { url: '' },
            thumbnail: embedData.thumbnail || { url: '' },
          };

          acc[`embed${index + 1}`] = {
            ...response.data,
            embedData: validatedEmbedData,
          };
        } catch (e) {
          console.error("Error parsing embed data:", e);
          acc[`embed${index + 1}`] = {
            ...response.data,
            embedData: null,
          };
        }
        return acc;
      }, {});

      setEmbeds(embedsData);
      updateProgress(80);

      const [voiceChannelsResponse, textChannelsResponse] = await Promise.all([
        axios.get(`${apiBaseUrl}/api/discord/get-voice-channels`, {
          params: { guildId: clanId, userId: String(user.discordId) },
          headers: { Authorization: `Bearer ${authToken}` },
        }),
        axios.get(`${apiBaseUrl}/api/discord/get-text-channels`, {
          params: { guildId: clanId, userId: String(user.discordId) },
          headers: { Authorization: `Bearer ${authToken}` },
        }),
      ]);

      const voiceChannelsFinal = [{ id: '', name: 'None' }, ...voiceChannelsResponse.data];
      const textChannelsFinal = [{ id: '', name: 'None' }, ...textChannelsResponse.data];

      setVoiceChannels(voiceChannelsFinal);
      setTextChannels(textChannelsFinal);
      //setFilteredTextChannels(textChannelsFinal);
      //setFilteredVoiceChannels(voiceChannelsFinal);

      updateProgress(100);
    } catch (error) {
      setError(error.response ? error.response.data.message : error.message);
    } finally {
      setLoading(false);
      updateProgress(100);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === 'checkbox' ? checked : value;

    console.log(`Changing ${name} to ${val}`); // Debugging line

    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: val,
    }));

    if (name === 'clanName') {
      setClanName(val);
    } else if (name === 'womGroupId') {
      setWomId(val);
    }
  };

  const handleEmbedFieldChange = (embedKey, fieldPath, value) => {
    setEmbeds((prevEmbeds) => {
      const newEmbed = { ...prevEmbeds[embedKey] };
      const keys = fieldPath.split('.');
      let obj = newEmbed.embedData;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!obj[keys[i]]) obj[keys[i]] = {};
        obj = obj[keys[i]];
      }
      obj[keys[keys.length - 1]] = value;
      return { ...prevEmbeds, [embedKey]: newEmbed };
    });
  };

  const handleAddField = (embedKey) => {
    setEmbeds((prevEmbeds) => {
      const newEmbed = { ...prevEmbeds[embedKey] };
      newEmbed.embedData.fields = newEmbed.embedData.fields || [];
      newEmbed.embedData.fields.push({ name: '', value: '', inline: false });
      return { ...prevEmbeds, [embedKey]: newEmbed };
    });
  };

  const handleRemoveField = (embedKey, fieldIndex) => {
    setEmbeds((prevEmbeds) => {
      const newEmbed = { ...prevEmbeds[embedKey] };
      newEmbed.embedData.fields.splice(fieldIndex, 1);
      return { ...prevEmbeds, [embedKey]: newEmbed };
    });
  };

  const serializeEmbeds = () => {
    const serializedEmbeds = {};
    for (const [embedKey, embedValue] of Object.entries(embeds)) {
      serializedEmbeds[embedKey] = {
        embedData: {
          ...embedValue.embedData,
          fields: embedValue.embedData.fields || [],
        },
        content: embedValue.content,
      };
    }
    return serializedEmbeds;
  };

  const handleSubmit = async () => {
    try {
      const authToken = localStorage.getItem('token');
      const serializedEmbeds = serializeEmbeds();

      const dataToSend = {
        settings: {
          clanName,
          clan_chat_name: settings.clan_chat_name,
          discord_invite_link: settings.discord_invite_link,
          womId: womId,
          minimum_value_to_send_drop: settings.minimum_value_to_send_drop,
          send_notifications_for_new_collection_logs: settings.send_notifications_for_new_collection_logs,
          send_notifications_for_new_personal_bests: settings.send_notifications_for_new_personal_bests,
          send_notifications_for_new_combat_achievements: settings.send_notifications_for_new_combat_achievements,
          loot_leaderboard_channel_id: settings.loot_leaderboard_channel_id,
          loot_leaderboard_message_id: settings.loot_leaderboard_message_id,
          channel_id_to_post_loot: settings.channel_id_to_post_loot,
          voice_channel_to_display_wom_group_member_count: settings.voice_channel_to_display_wom_group_member_count,
          voice_channel_to_display_monthly_loot: settings.voice_channel_to_display_monthly_loot,
        },
        embeds: serializedEmbeds,
      };

      // Properly serialize the data
      const jsonData = JSON.stringify(dataToSend);

      await axios.post(`${apiBaseUrl}/clan/update-config`, jsonData, {
        params: { clanId: clanDTId },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });

      alert('Settings updated successfully.');
    } catch (error) {
      alert('Failed to update settings. Please try again.');
    }
  };

  if (loading) {
    return (
        <center>
          <div className="progress" style={{ marginTop: '10%', width: '25%' }}>
            <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${progress}%` }}>
              {progress}%
            </div>
          </div>
        </center>
    );
  }

  if (userClans.length > 1 && !clanDTId) {
    return (
        <div className="container">
          <div className="card">
            <div className="card-body">
              <h2>Select Clan</h2>
              <ul>
                {userClans.map((clan) => (
                    <li key={clan.discordServerId} onClick={() => selectClan(clan.discordServerId, localStorage.getItem('token'))}>
                      {clan.displayName}
                    </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
    );
  }
  console.log("Settings::: " + settings);
  if (!settings) {
    return (
        <div className="container">
          <div className="card">
            <div className="card-body">
              <h2>Loading...</h2>
            </div>
          </div>
        </div>
    );
  }

  if (error) return <div>Error: {error}</div>;

  return (
      <div className="container">
        <Helmet>
          {/* Primary Meta Tags */}
          <title>Clan Configuration - DropTracker.io</title>
          <meta name="title" content="Clan Configuration - DropTracker.io" />
          <meta name="description" content="Configure your clan to integrate WiseOldMan, integrate Google Sheets, and send notifications for various Old School RuneScape drops + events." />
          <meta name="keywords" content="droptracker, osrs, clan, tracking, drops, metrics, events" />
          <meta name="author" content="droptracker.io" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />

          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.droptracker.io/clan/config" />
          <meta property="og:title" content="Clan Configuration - DropTracker.io" />
          <meta property="og:description" content="Configure your clan to integrate WiseOldMan, integrate Google Sheets, and send notifications for various Old School RuneScape drops + events." />
          <meta property="og:image" content="https://www.droptracker.io/img/droptracker-small.gif" />

          {/* Additional Meta Tags */}
          <link rel="canonical" href="https://www.droptracker.io/clan/edit" />
          <link rel="icon" href="/favicon.ico" type="image/x-icon" />
          <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
          <meta name="theme-color" content="#ffffff" />
        </Helmet>
        <div className="row justify-content-center">
          <div className="col-xl-9">
            <div id="general" className="mb-5">
              <h2><i className="far fa-user fa-fw text-theme"></i> Server Configuration</h2>
              <h3>
                <mark>'Add Field' on the Embed Builders currently adds two fields (bug). Remove the furthest-down one to
                  keep only one.
                </mark>
              </h3>
              <h4>Modifying configuration settings for server ID: <code></code></h4>
              <p>Set up the Discord bot to operate within your own Discord server and manage your clan's settings.</p>
              <br /><br />
              <p>Note: You must press <mark>Save</mark> at the bottom of the page to publish your changes.</p>
              <div className="card">
                <div className="list-group list-group-flush">
                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-1 text-break">
                      <div>Clan Name</div>
                      <input
                          type="text"
                          name="clanName"
                          value={clanName || ''}
                          onChange={handleChange}
                          className="form-control"
                      />
                    </div>
                  </div>
                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-1 text-break">
                      <div>CC</div>
                      <input
                          type="text"
                          name="clan_chat_name"
                          value={settings.clan_chat_name || ''}
                          onChange={handleChange}
                          className="form-control"
                      />
                    </div>
                  </div>
                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-1 text-break">
                      <div>Discord Link</div>
                      <input
                          type="text"
                          name="discord_invite_link"
                          value={settings.discord_invite_link || ''}
                          onChange={handleChange}
                          className="form-control"
                      />
                    </div>
                  </div>
                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-1 text-break">
                      <div>WiseOldMan Group ID</div>
                      <input
                          type="text"
                          name="womGroupId"
                          value={womId || ''}
                          onChange={handleChange}
                          className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="card-arrow">
                  <div className="card-arrow-top-left"></div>
                  <div className="card-arrow-top-right"></div>
                  <div className="card-arrow-bottom-left"></div>
                  <div className="card-arrow-bottom-right"></div>
                </div>
              </div>
            </div>

            <div id="notifications" className="mb-5">
              <h4><i className="far fa-bell fa-fw text-theme"></i> Notifications</h4>
              <p>Modify what drops you want to send, and how you want them to be sent to Discord.</p>
              <div className="card">
                <div className="list-group list-group-flush">
                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-1 text-break">
                      <label className="form-label">Minimum Value for Notifications</label>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="range"
                            name="minimum_value_to_send_drop"
                            min="50000"
                            max="100000000"
                            step="25000"
                            value={settings.minimum_value_to_send_drop}
                            onChange={handleChange}
                            className="form-range"
                        />
                        <input
                            type="number"
                            name="minimum_value_to_send_drop"
                            min="50000"
                            max="100000000"
                            step="25000"
                            value={settings.minimum_value_to_send_drop}
                            onChange={handleChange}
                            className="form-control"
                            style={{ width: '100px', marginLeft: '10px' }}
                        />
                      </div>
                      <span>{formatNumber(settings.minimum_value_to_send_drop)}</span>
                    </div>
                  </div>
                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-1 text-break">
                      <div className="form-check form-switch">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            name="send_notifications_for_new_collection_logs"
                            checked={settings.send_notifications_for_new_collection_logs}
                            onChange={handleChange}
                        />
                        <label className="form-check-label">Collection Logs</label>
                      </div>
                    </div>
                  </div>
                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-1 text-break">
                      <div className="form-check form-switch">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            name="send_notifications_for_new_personal_bests"
                            checked={settings.send_notifications_for_new_personal_bests}
                            onChange={handleChange}
                        />
                        <label className="form-check-label">Personal Bests</label>
                      </div>
                    </div>
                  </div>
                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-1 text-break">
                      <div className="form-check form-switch">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            name="send_notifications_for_new_combat_achievements"
                            checked={settings.send_notifications_for_new_combat_achievements}
                            onChange={handleChange}
                        />
                        <label className="form-check-label">Combat Achievements</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-arrow">
                  <div className="card-arrow-top-left"></div>
                  <div className="card-arrow-top-right"></div>
                  <div className="card-arrow-bottom-left"></div>
                  <div className="card-arrow-bottom-right"></div>
                </div>
              </div>
            </div>
            <div id="channelsAndMessages" className="mb-5">
              <h4><i className="fa fa-link fa-fw text-theme"></i> Channels & Messages</h4>
              <p>Configure what Discord channels you want messages to be sent or read from.</p>
              <div className="card">
                <div className="list-group list-group-flush">
                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-1 text-break">
                      <div>What channel do you want your loot-board posted inside of? *<br />
                        This will host the "leaderboard" image, resetting monthly. Updates every 10 minutes ~.
                      </div>
                      <input
                          type="text"
                          value={lootBoardChannel}
                          onFocus={() => setOpenDropdown('lootBoard')}
                          onChange={(e) => handleFilterChannels('lootBoard', e)}
                          placeholder="Type to search..."
                          className="form-control"
                      />
                      {openDropdown === 'lootBoard' && filterText && (
                          <div className="dropdown-menu show" style={{ width: '100%', maxHeight: '200px', overflowY: 'auto' }}>
                            {filteredLootBoardChannels.map((channel) => (
                                <div
                                    key={channel.id}
                                    onClick={() => handleChannelSelect('lootBoard', channel)}
                                    className="dropdown-item"
                                >
                                  {channel.name}
                                </div>
                            ))}
                          </div>
                      )}
                    </div>
                  </div>

                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-1 text-break">
                      <div>What's the message ID for your loot-board? (leave blank, with a channel configured above to
                        create one)
                      </div>
                      <input
                          type="text"
                          name="loot_leaderboard_message_id"
                          value={settings.loot_leaderboard_message_id || ''}
                          onChange={handleChange}
                          className="form-control"
                      />
                    </div>
                  </div>

                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-1 text-break">
                      <div>What <strong>text channel</strong> do you want the DropTracker bot to send your clan's drops
                        to?
                      </div>
                      <input
                          type="text"
                          value={dropChannel}
                          onFocus={() => setOpenDropdown('drop')}
                          onChange={(e) => handleFilterChannels('drop', e)}
                          placeholder="Type to search..."
                          className="form-control"
                      />
                      {openDropdown === 'drop' && filterText && (
                          <div className="dropdown-menu show" style={{ width: '100%', maxHeight: '200px', overflowY: 'auto' }}>
                            {filteredDropChannels.map((channel) => (
                                <div
                                    key={channel.id}
                                    onClick={() => handleChannelSelect('drop', channel)}
                                    className="dropdown-item"
                                >
                                  {channel.name}
                                </div>
                            ))}
                          </div>
                      )}
                    </div>
                  </div>

                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-1 text-break">
                      <div>What <strong>voice channel</strong> do you want to update regularly with your <strong>tracking
                        member count</strong>?
                      </div>
                      <input
                          type="text"
                          value={womMemberCountVoiceChannel}
                          onFocus={() => setOpenDropdown('womMemberCount')}
                          onChange={(e) => handleFilterChannels('womMemberCount', e)}
                          placeholder="Type to search..."
                          className="form-control"
                      />
                      {openDropdown === 'womMemberCount' && filterVoice && (
                          <div className="dropdown-menu show" style={{ width: '100%', maxHeight: '200px', overflowY: 'auto' }}>
                            {filteredWomMemberCountChannels.map((channel) => (
                                <div
                                    key={channel.id}
                                    onClick={() => handleChannelSelect('womMemberCount', channel)}
                                    className="dropdown-item"
                                >
                                  {channel.name}
                                </div>
                            ))}
                          </div>
                      )}
                    </div>
                  </div>

                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-1 text-break">
                      <div>What <strong>voice channel</strong> do you want to update regularly with your <strong>total
                        monthly loot</strong>?
                      </div>
                      <input
                          type="text"
                          value={monthlyLootVoiceChannel}
                          onFocus={() => setOpenDropdown('monthlyLoot')}
                          onChange={(e) => handleFilterChannels('monthlyLoot', e)}
                          placeholder="Type to search..."
                          className="form-control"
                      />
                      {openDropdown === 'monthlyLoot' && filterVoice && (
                          <div className="dropdown-menu show" style={{ width: '100%', maxHeight: '200px', overflowY: 'auto' }}>
                            {filteredMonthlyLootChannels.map((channel) => (
                                <div
                                    key={channel.id}
                                    onClick={() => handleChannelSelect('monthlyLoot', channel)}
                                    className="dropdown-item"
                                >
                                  {channel.name}
                                </div>
                            ))}
                          </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-arrow">
                  <div className="card-arrow-top-left"></div>
                  <div className="card-arrow-top-right"></div>
                  <div className="card-arrow-bottom-left"></div>
                  <div className="card-arrow-bottom-right"></div>
                </div>
              </div>
            </div>

            <div className="card mt-4">
              <div className="card-body" id="embeds">
                <h2>Embeds</h2>
                <p>Configure the Discord embeds for your clan.</p>
                {Object.keys(embeds).map((embedKey, index) => {
                  const embedData = embeds[embedKey].embedData;
                  const content = embeds[embedKey].content;
                  return (
                      <div key={index} className="embed-editor-container mb-4 d-flex"
                           style={{ alignItems: 'flex-start', width: '100%' }}>
                        <div className="embed-editor-fields"
                             style={{ width: '60%', overflowY: 'auto', paddingRight: '2%' }}>
                          <h4>
                            {(() => {
                              if (index === 0) return "Loot Leaderboard";
                              if (index === 1) return "Drop Submission";
                              if (index === 2) return "Collection Log Submission";
                              if (index === 3) return "CA Submission";
                              return `Embed ${index + 1}`;
                            })()}
                          </h4>
                          <div className="mb-3">
                            <label>Title</label>
                            <input
                                type="text"
                                name="title"
                                value={embedData.title || ''}
                                onChange={(e) => handleEmbedFieldChange(embedKey, 'title', e.target.value)}
                                className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label>Description</label>
                            <textarea
                                name="description"
                                value={embedData.description || ''}
                                onChange={(e) => handleEmbedFieldChange(embedKey, 'description', e.target.value)}
                                className="form-control"
                                rows="3"
                            />
                          </div>
                          <div className="mb-3">
                            <label>Color</label>
                            <input
                                type="text"
                                name="color"
                                value={embedData.color || ''}
                                onChange={(e) => handleEmbedFieldChange(embedKey, 'color', e.target.value)}
                                className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label>Author Name</label>
                            <input
                                type="text"
                                name="author.name"
                                value={embedData.author?.name || ''}
                                onChange={(e) => handleEmbedFieldChange(embedKey, 'author.name', e.target.value)}
                                className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label>Author Icon URL</label>
                            <input
                                type="text"
                                name="author.icon_url"
                                value={embedData.author?.icon_url || ''}
                                onChange={(e) => handleEmbedFieldChange(embedKey, 'author.icon_url', e.target.value)}
                                className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label>Footer Text</label>
                            <input
                                type="text"
                                name="footer.text"
                                value={embedData.footer?.text || ''}
                                onChange={(e) => handleEmbedFieldChange(embedKey, 'footer.text', e.target.value)}
                                className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label>Footer Icon URL</label>
                            <input
                                type="text"
                                name="footer.icon_url"
                                value={embedData.footer?.icon_url || ''}
                                onChange={(e) => handleEmbedFieldChange(embedKey, 'footer.icon_url', e.target.value)}
                                className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label>Thumbnail URL</label>
                            <input
                                type="text"
                                name="thumbnail.url"
                                value={embedData.thumbnail?.url || ''}
                                onChange={(e) => handleEmbedFieldChange(embedKey, 'thumbnail.url', e.target.value)}
                                className="form-control"
                            />
                          </div>
                          <div className="mb-3">
                            <label>Image URL</label>
                            <input
                                type="text"
                                name="image.url"
                                value={embedData.image?.url || ''}
                                onChange={(e) => handleEmbedFieldChange(embedKey, 'image.url', e.target.value)}
                                className="form-control"
                            />
                          </div>
                          {embedData.fields?.map((field, fieldIndex) => (
                              <div key={fieldIndex} className="field-container d-flex align-items-center mb-2">
                                <div style={{ flex: 1 }}>
                                  <label>Field Name</label>
                                  <input
                                      type="text"
                                      name={`fields.${fieldIndex}.name`}
                                      value={field.name || ''}
                                      onChange={(e) =>
                                          handleEmbedFieldChange(embedKey, `fields.${fieldIndex}.name`, e.target.value)
                                      }
                                      className="form-control"
                                  />
                                </div>
                                <div style={{ flex: 2, marginLeft: '10px' }}>
                                  <label>Field Value</label>
                                  <textarea
                                      name={`fields.${fieldIndex}.value`}
                                      value={field.value || ''}
                                      onChange={(e) =>
                                          handleEmbedFieldChange(embedKey, `fields.${fieldIndex}.value`, e.target.value)
                                      }
                                      className="form-control"
                                      rows="1"
                                  />
                                </div>
                                <div className="form-check" style={{ marginLeft: '10px' }}>
                                  <input
                                      type="checkbox"
                                      name={`fields.${fieldIndex}.inline`}
                                      checked={field.inline || false}
                                      onChange={(e) =>
                                          handleEmbedFieldChange(embedKey, `fields.${fieldIndex}.inline`, e.target.checked)
                                      }
                                      className="form-check-input"
                                  />
                                  <label className="form-check-label">Inline</label>
                                </div>
                                <button onClick={() => handleRemoveField(embedKey, fieldIndex)}
                                        className="btn btn-danger ms-2">Remove
                                </button>
                              </div>
                          ))}
                          <button
                              key={`add-field-button-${embedKey}`}
                              onClick={() => handleAddField(embedKey)}
                              className="btn btn-primary mt-3"
                          >
                            Add Field
                          </button>
                        </div>
                        <div className="embed-builder-container" style={{ marginLeft: '2%' }}>
                          <EmbedBuilder
                              key={index}
                              embed={embedData}
                              content={content}
                          />
                        </div>
                      </div>
                  );
                })}
              </div>
              <div className="card-arrow">
                <div className="card-arrow-top-left"></div>
                <div className="card-arrow-top-right"></div>
                <div className="card-arrow-bottom-left"></div>
                <div className="card-arrow-bottom-right"></div>
              </div>
            </div>
            <div id="saveSettings" className="pt-5 mb-5">
              <h4><i className="fa fa-redo fa-fw text-theme"></i> Save settings</h4>
              <div className="card">
                <div className="list-group list-group-flush">
                  <div className="list-group-item d-flex align-items-center">
                    <div className="flex-1 text-break">
                      <div className="text-inverse text-opacity-50">
                        <strong>Note</strong>: While if you run into issues with your new configuration, please reach out
                        on Discord.
                      </div>
                      <br />
                      <center>
                        <a href="#" onClick={handleSubmit} className="btn btn-outline-default w-100px">Save</a>
                      </center>
                    </div>
                    <div>
                    </div>
                  </div>
                </div>
                <div className="card-arrow">
                  <div className="card-arrow-top-left"></div>
                  <div className="card-arrow-top-right"></div>
                  <div className="card-arrow-bottom-left"></div>
                  <div className="card-arrow-bottom-right"></div>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-end mt-4">
            </div>
          </div>

          <div className="col-xl-3">
            <nav id="sidebar-bootstrap" className="navbar navbar-sticky d-none d-xl-block">
              <nav className="nav">
                <a className="nav-link" href="#general" data-toggle="scroll-to">General</a>
                <a className="nav-link" href="#notifications" data-toggle="scroll-to">Notifications</a>
                <a className="nav-link" href="#channelsAndMessages" data-toggle="scroll-to">Channels and Messages</a>
                <a className="nav-link" href="#embeds" data-toggle="scroll-to">Embeds</a>
                <a className="nav-link" href="#saveSettings" data-toggle="scroll-to">Save changes</a>
              </nav>
            </nav>
          </div>
        </div>
      </div>
  );
};

export default ServerConfigPage;