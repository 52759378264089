// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.embed-editor-container {
  width: 100%;
}

.embed-builder-container {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
}

.embed-editor-fields {
  width: 250%;
  overflow-y: auto;
}

.field-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.field-container label {
  margin-right: 10px;
}

.field-container .form-control {
  flex: 1 1;
}

.field-container .form-check {
  margin-left: 10px;
}

.field-container button {
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/clan/ServerConfigPage.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".embed-editor-container {\n  width: 100%;\n}\n\n.embed-builder-container {\n  margin-bottom: 2rem;\n  display: flex;\n  justify-content: center;\n}\n\n.embed-editor-fields {\n  width: 250%;\n  overflow-y: auto;\n}\n\n.field-container {\n  display: flex;\n  align-items: center;\n  margin-bottom: 1rem;\n}\n\n.field-container label {\n  margin-right: 10px;\n}\n\n.field-container .form-control {\n  flex: 1;\n}\n\n.field-container .form-check {\n  margin-left: 10px;\n}\n\n.field-container button {\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
