// src/pages/DropFwdPage.jsx
import React, { useEffect } from 'react';

const DropFwdPage = () => {
    useEffect(() => {
        const handleRequest = async () => {
            const urlParams = new URLSearchParams(window.location.search);

            const drop_type = urlParams.get('drop_type');
            const auth_token = urlParams.get('auth_token');
            const item_name = urlParams.get('item_name');
            const item_id = urlParams.get('item_id');
            const player_name = urlParams.get('player_name');
            const real_name = urlParams.get('real_name');
            const server_id = urlParams.get('server_id');
            const quantity = urlParams.get('quantity');
            const value = urlParams.get('value');
            const nonmember = urlParams.get('nonmember');
            const member_list = urlParams.get('member_list');
            const image_url = urlParams.get('image_url');
            const npc_name = urlParams.get('npc_name');
            const webhook = urlParams.get('webhook');
            const webhookValue = urlParams.get('webhookValue');
            const sheet = urlParams.get('sheet');
            const notified = urlParams.get('notified');

            const drop = {
                itemName: item_name,
                itemId: item_id,
                rsn: player_name,
                quantity,
                value,
                time: new Date(),
                notified,
                imageUrl: image_url,
                npcName: npc_name,
                sheetUrl: sheet,
                token: auth_token,
            };

            console.log("API connection drop received", drop);

            try {
                const response = await fetch('http://localhost:21220/api/drops/submit', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(drop),
                });

                const result = await response.json();

                if (response.ok) {
                    console.log('Drop forwarded successfully:', result);
                } else {
                    console.error('Error forwarding drop:', result);
                }
            } catch (error) {
                console.error('Internal server error:', error);
            }
        };

        handleRequest();
    }, []);

    return (
        <div>
            <h1>Drop Forwarding Page</h1>
            <p>Processing the drop...</p>
        </div>
    );
};

export default DropFwdPage;
