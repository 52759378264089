import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ExtRedir = ({ url }) => {
    useEffect(() => {
        window.location.href = url;
    }, [url]);

    return null;
};

export default ExtRedir;