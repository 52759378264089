import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto'; // This imports the required chart.js modules

const ProfilePost = ({ userId }) => {
  const [drops, setDrops] = useState([]);
  const [chartData, setChartData] = useState({});
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { user } = useContext(AuthContext);
  const commentsEnabled = false;

  useEffect(() => {
    if (userId) {
      axios.get(`${apiBaseUrl}/api/users/${userId}/drops/month`)
        .then(response => {
          const dropsData = response.data || [];
          prepareChartData(dropsData);
        })
        .catch(error => console.error('Error fetching drops:', error));
    }
  }, [userId, apiBaseUrl]);

  const prepareChartData = (dropsData) => {
    const labels = dropsData.map(drop => drop.date);
    const values = dropsData.map(drop => parseFloat(drop.total_value) / 1e6); // Convert to millions

    const data = {
      labels,
      datasets: [{
        data: values,
        fill: false,
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
      }]
    };

    const options = {
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            callback: function(value) {
              return value + 'M'; // Add 'M' to the tick labels
            }
          }
        }
      },
      plugins: {
        legend: {
          display: false // Hide the legend
        }
      },
      responsive: true,
      maintainAspectRatio: false
    };

    setChartData({ data, options });
  };

  useEffect(() => {
    if (userId) {
      axios.get(`${apiBaseUrl}/api/users/${userId}/drops`)
        .then(response => {
          const dropsData = response.data || [];
          setDrops(dropsData);
        })
        .catch(error => console.error('Error fetching drops:', error));
    }
  }, [userId, apiBaseUrl]);

  if (drops.length === 0) {
    return <div>No drops available.</div>;
  }

  return (
    <div>
      <div className="card-body">
        <div className="d-flex align-items-center mb-2">
          <div className="flex-fill fw-bold fs-16px">Statistics</div>
          {/*<a href="#" className="text-decoration-none text-inverse text-opacity-50">View report</a>/*
            */}
          </div>

        <div className="d-flex align-items-center h4">
          {/*<small style={{ fontSize: '14px', paddingLeft: '5px' }} className="fw-bold mb-2 text-inverse text-opacity-50">received this month</small>
          <small className="fw-400 ms-auto text-theme">+5%</small>*/}
        </div>
        <div className="card mb-3">
        <div className="card-body">
          <div className="fs-12px fw-bold mb-2 text-inverse text-opacity-50">LOOT BY DAY</div>
          <div className="chart mb-2" style={{ height: `300px` }}>
            <Line data={chartData.data} options={chartData.options} />
          </div>
        </div>
        
            <div className="card-arrow">
              <div className="card-arrow-top-left"></div>
              <div className="card-arrow-top-right"></div>
              <div className="card-arrow-bottom-left"></div>
              <div className="card-arrow-bottom-right"></div>
            </div>
        </div>
      </div>
      <div className="tab-pane fade show active" id="profile-drops">
        {drops.map((drop, index) => (
          <div className="card mb-3" key={index}>
            <div className="card-body">
              <div className="d-flex align-items-center mb-3">
                <a href="#"><img src="assets/img/user/profile.jpg" alt="" width="50" className="rounded-circle" /></a>
                <div className="flex-fill ps-2">
                  <div className="fw-bold">{drop.rsn} received a <a href="#" className="text-decoration-none">{drop.itemName}</a> from <a href="#" className="text-decoration-none">{drop.npcName}</a></div>
                  <div className="small text-inverse text-opacity-50">Value: {drop.total_value}</div>
                  <div className="small text-inverse text-opacity-25">{new Date(drop.time).toLocaleString()}</div>
                </div>
              </div>
              
              <div className="full-size-image mb-3 profile-img-list">
                <a href={drop.imageUrl} data-lity className="profile-img-list-link">
                  <img src={drop.imageUrl} alt={drop.itemName} className="img-fluid" />
                </a>
              </div>
              {commentsEnabled && (
                <>
                  <hr className="mb-1" />
                  <div className="row text-center fw-bold">
                    <div className="col">
                      <a href="#" className="text-inverse text-opacity-50 text-decoration-none d-block p-2">
                        <i className="far fa-thumbs-up me-1"></i> Like
                      </a>
                    </div>
                    <div className="col">
                      <a href="#" className="text-inverse text-opacity-50 text-decoration-none d-block p-2">
                        <i className="far fa-comment me-1"></i> Comment
                      </a>
                    </div>
                    <div className="col">
                      <a href="#" className="text-inverse text-opacity-50 text-decoration-none d-block p-2">
                        <i className="fa fa-share me-1"></i> Share
                      </a>
                    </div>
                  </div>
                  <hr className="mb-3 mt-1" />
                  <div className="d-flex align-items-center">
                    <img src="assets/img/user/user.jpg" alt="" width="35" className="rounded-circle" />
                    <div className="flex-fill ps-2">
                      <div className="position-relative d-flex align-items-center">
                        <input type="text" className="form-control rounded-pill bg-white bg-opacity-15" style={{ paddingRight: '120px' }} placeholder="Write a comment..." />
                        <div className="position-absolute end-0 text-center">
                          <a href="#" className="text-inverse text-opacity-50 me-2"><i className="fa fa-smile"></i></a>
                          <a href="#" className="text-inverse text-opacity-50 me-2"><i className="fa fa-camera"></i></a>
                          <a href="#" className="text-inverse text-opacity-50 me-2"><i className="fa fa-video"></i></a>
                          <a href="#" className="text-inverse text-opacity-50 me-3"><i className="fa fa-paw"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="card-arrow">
              <div className="card-arrow-top-left"></div>
              <div className="card-arrow-top-right"></div>
              <div className="card-arrow-bottom-left"></div>
              <div className="card-arrow-bottom-right"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProfilePost;
