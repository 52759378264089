import React from 'react';
import './EmbedBuilder.css';
import { DiscordOptionsContext, DiscordMessage, DiscordMessages, DiscordEmbed, DiscordEmbedFields, DiscordEmbedField } from '@danktuary/react-discord-message';

// Function to convert markdown links to HTML <a> tags and replace newline characters with <br> tags
const convertMarkdownToHtml = (text) => {
  if (!text) return '';
  const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g;
  const newlineRegex = /\n/g;
  return text.replace(linkRegex, '<a href="$2">$1</a>').replace(newlineRegex, '<br />');
};

const EmbedBuilder = ({ embed, content }) => {
  const defaultEmbed = {
    author: { name: '', icon_url: '' },
    title: '',
    description: '',
    color: '',
    fields: [],
    footer: { text: '', icon_url: '' },
    thumbnail: { url: '' },
    image: { url: '' },
  };

  const currentEmbed = { ...defaultEmbed, ...embed };

  return (
    <div className="embed-builder-container">
        <DiscordOptionsContext.Provider value={{ profiles: {}, avatars: {} }}>
          <DiscordMessages>
            <DiscordMessage author="DropTracker.io" avatar="https://www.droptracker.io/img/droptracker-small.gif">
              {content}
              <DiscordEmbed
                slot="embeds"
                color={currentEmbed.color.toString()}
                author={{
                  name: "DropTracker.io",
                  icon_url: "https://www.droptracker.io/img/droptracker-small.gif",
                }}
                title={convertMarkdownToHtml(currentEmbed.title)}
                footer={{
                  text: convertMarkdownToHtml(currentEmbed.footer.text),
                  icon_url: currentEmbed.footer.icon_url,
                }}
                thumbnail={currentEmbed.thumbnail.url}
                image={currentEmbed.image.url}
              >
                <span dangerouslySetInnerHTML={{ __html: convertMarkdownToHtml(currentEmbed.description) }} />
                <DiscordEmbedFields slot="fields">
                  {currentEmbed.fields.map((field, index) => (
                    <DiscordEmbedField key={index} fieldTitle={convertMarkdownToHtml(field.name)} inline={field.inline}>
                      <span dangerouslySetInnerHTML={{ __html: convertMarkdownToHtml(field.value) }} />
                    </DiscordEmbedField>
                  ))}
                </DiscordEmbedFields>
              </DiscordEmbed>
            </DiscordMessage>
          </DiscordMessages>
        </DiscordOptionsContext.Provider>
    </div>
  );
};

export default EmbedBuilder;
