import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Profile from './profile/Profile';
import { AuthContext } from '../context/AuthContext';

const ProfilePage = () => {
  const { userId: paramUserId } = useParams();
  const { user } = useContext(AuthContext);
  const [userId, setUserId] = useState(paramUserId || null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    const fetchUserId = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/player/get-id`, {
          params: { discordId: user.discordId },
          headers: { Authorization: `Bearer ${user.token}` }
        });
        setUserId(response.data.uid);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch user ID.');
        setLoading(false);
      }
    };

    if (!paramUserId) {
      fetchUserId();
    } else {
      setLoading(false);
    }
  }, [user, navigate, paramUserId, apiBaseUrl]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="card">
      <div className="card-body p-0">
        {userId ? <Profile userId={userId} /> : <div>Unable to load profile.</div>}
      </div>
      <div className="card-arrow">
        <div className="card-arrow-top-left"></div>
        <div className="card-arrow-top-right"></div>
        <div className="card-arrow-bottom-left"></div>
        <div className="card-arrow-bottom-right"></div>
      </div>
    </div>
  );
};

export default ProfilePage;
