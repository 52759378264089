import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import HomePage from './components/HomePage';
import ProfilePage from './components/ProfilePage';
import WelcomePageOne from './components/welcome/WelcomePageOne';
import WelcomePageTwo from './components/welcome/WelcomePageTwo';
import Documentation from './components/pages/Documentation';
import UserSettingsPage from './components/pages/UserSettingsPage';
import DropFwdPage from './components/DropFwdPage';
import EmbedBuilder from './components/embed/EmbedBuilder';
import Login from './components/Login';
import Register from './components/Register';
import AuthProvider from './context/AuthContext';
import SlotPage from './components/casino/SlotPage';
import Analytics from './components/pages/Analytics';
import ExtRedir from './util/ExtRedir';
import ServerConfigPage from './components/clan/ServerConfigPage';
import NotFoundPage from './components/pages/NotFound'; // Import the NotFoundPage component

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <AuthProvider>
      <div id="app" className="app">
        <Header toggleSidebar={toggleSidebar} />
        <Sidebar isSidebarOpen={isSidebarOpen} />
        <div id="content" className="app-content">
          <button className="app-sidebar-mobile-backdrop" data-toggle-target=".app" data-toggle-class="app-sidebar-mobile-toggled"></button>

          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile/:userId" element={<ProfilePage />} />
            <Route path="/api/drops/submit" element={<DropFwdPage />} />
            <Route path="/profile" element ={<ProfilePage />} />
            <Route path="/profile/settings" element={<UserSettingsPage />} />
            <Route path="/documentation" element ={<Documentation />} />
            <Route path="/docs" element ={<Documentation />} />
            <Route path="/welcome" element={<WelcomePageOne />} />
            <Route path="/welcome2" element={<WelcomePageTwo />} />
            <Route path="/clan/config" element={<ServerConfigPage />} />
            <Route path="/invite" element={<ExtRedir url="https://discord.com/oauth2/authorize?client_id=1172933457010245762" />} />
            <Route path="/discord" element={<ExtRedir url="https://discord.gg/htEKyfCQdu" />} />
            <Route path="/docs/db" element={<ExtRedir url="https://dbdocs.io/boofy.games21/DropTracker-Database-Structure" />} />

            <Route path="/runelite" element={<ExtRedir url="https://runelite.dev/plugin-hub/show/droptracker" />} />
            <Route path="/github" element={<ExtRedir url="https://www.github.com/joelhalen/droptracker-plugin" />} />
            <Route path="/clan/embed/edit" element={<EmbedBuilder />} />
            <Route path="*" element={<NotFoundPage />} /> {/* Add this route for 404 page */}
          </Routes>
        </div>
      </div>
    </AuthProvider>
  );
}

export default App;
